import React from "react";


class Footer extends React.Component {
    render() {
        return (
        <footer className="extras">
            <p> Developed by Joshua Olaoye</p>
        </footer>
        );
    }
}

export default Footer;